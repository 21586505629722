import React from "react";
import "./src/styles/global.scss";
import "./src/styles/variables.scss";
import "react-toastify/dist/ReactToastify.min.css";

import { ThemeProvider } from "./src/context/ThemeContext";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
);

export const onRouteUpdate = ({ location }) => {
  if (
    location.pathname === "/web-experiences" ||
    location.pathname === "/ux-design" ||
    location.pathname === "/mobile-solutions" ||
    location.pathname === "/e-commerce" ||
    location.pathname === "/product-documentation" ||
    location.pathname === "/online-communities" ||
    location.pathname === "/support-tools-portals" ||
    location.pathname === "/product-development" ||
    location.pathname === "/adobe-experience-manager" ||
    location.pathname === "/adobe-experience-manager-forms" ||
    location.pathname === "/adobe-analytics" ||
    location.pathname === "/adobe-target" ||
    location.pathname === "/adobe-campaign" ||
    location.pathname === "/adobe-commerce-magento" ||
    location.pathname === "/jam-stack" ||
    location.pathname === "/headless-cms" ||
    location.pathname === "/salesforce-experience-cloud" ||
    location.pathname === "/join-us"
  ) {
    require("./src/styles/solutions/style.scss");
  } else {
    require("./src/styles/global.scss");
    require("./src/styles/variables.scss");
  }
};
