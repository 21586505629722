import React from 'react';
import CONSTANTS from '../constants';

const defaultState = {
    theme: CONSTANTS.THEMES.light,
    setTheme: () => { },
}

const ThemeContext = React.createContext(defaultState);

class ThemeProvider extends React.Component {
    state = {
        theme: CONSTANTS.THEMES.light
    }

    setTheme = theme => {
        this.setState({
            theme
        })
    }

    render() {
        const { children } = this.props
        const { theme } = this.state
        return (
            <ThemeContext.Provider
                value={{
                    theme,
                    setTheme: this.setTheme,
                }}
            >
                {children}
            </ThemeContext.Provider>
        )
    }
}

export default ThemeContext
export { ThemeProvider }
